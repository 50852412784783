<template>
<div id="Marks">

<!-- notification -->
<v-snackbar v-model="notificationToggle" :color="notificationType" :timeout="5000" :top="true"> {{ notificationMsg }}
  <v-btn dark text @click="notificationToggle = false" > Close </v-btn>
</v-snackbar>

<!-- add form -->
<v-form @submit.prevent="AddMarks">
<v-container class="pa-5">
<v-card class="pa-2 mt-5 elevation-5">
  <v-card-title>
    <h3>Add Marks</h3>
  </v-card-title>
  <v-card-text class="pa-2">
    <v-row>
      <!-- add form -->
      <v-col cols="12" sm="6" md="6">
          <v-autocomplete label="Month" :items="months" v-model="Month"></v-autocomplete>
      </v-col>
      <v-col cols="12" sm="6" md="6">
          <v-text-field type="number" label="Year" v-model="Year"></v-text-field>
      </v-col>
      <v-col cols="12" sm="6" md="3">
          <v-text-field type="tel" label="Week 1" v-model="Week1"></v-text-field>
      </v-col>
      <v-col cols="12" sm="6" md="3">
          <v-text-field type="tel" label="Week 2" v-model="Week2"></v-text-field>
      </v-col>
      <v-col cols="12" sm="6" md="3">
          <v-text-field type="tel" label="Week 3" v-model="Week3"></v-text-field>
      </v-col>
      <v-col cols="12" sm="6" md="3">
          <v-text-field type="tel" label="Week 4" v-model="Week4"></v-text-field>
      </v-col>
      <v-col cols="12" sm="12" md="12">
        <v-checkbox v-model="Activeness" :label="`Active: ${Activeness.toString()}`" ></v-checkbox>
      </v-col>
    </v-row>
  </v-card-text>
  <v-card-actions class="pa-3">
      <v-btn type="submit" class="success elevation-10">Add Marks</v-btn>
      <v-btn @click="$router.go(-1)" class="secondary elevation-10">back</v-btn>
  </v-card-actions>
</v-card>
</v-container>
</v-form>

</div>
</template>

<script>
export default {

    name: 'Marks',

    created(){this.$store.dispatch('getMarks');},

    mounted(){
      this.selectedMarkFromLoop = this.MarkData;
      this.MarkData != null ? this.assignData(this.MarkData) : null;
    },

    data(){return{
        // app flags
        notificationToggle: false,notificationMsg: 'hai',notificationType: 'hui',

        // data
        Month: '',Week1: 0,Week2: 0,Week3: 0,Week4: 0,Year: new Date().getFullYear(),selectedMarkFromLoop: [],Activeness: false,
        months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
    }},

    computed: {

        StudentId(){         
          return this.$route.params.id != null ? this.$route.params.id : null;
        },

        MarkData(){
            if(this.StudentId != null) {
                let data =  this.$store.getters.GetMarkWithID(this.StudentId);
                if(data != undefined || data != []){ return data[0];}else{ return this.$router.push("/students"); }
            }else{ return this.$router.push("/students"); }             
        }
        
    },

    methods: {

      AddMarks(){
          console.log(this.Week1)
      },

      // assign curresponsing data to student
      assignData(selectedData){
        if(selectedData  != null){
            this.Week1 = selectedData.week1;this.Week2 = selectedData.week2;
            this.Week3 = selectedData.week3;this.Week4 = selectedData.week4;
            this.Month = selectedData.month;
        }else{return null}
      },

      // notification service
      notificationService(msg, type){
        this.notificationType = type;
        this.notificationMsg = msg;
        this.notificationToggle = true;
      },

      // reset fields
      ResetFields(){     
        this.files = null;this.Title = '';this.Description = '';this.DOE = '';
        this.$v.$reset();
      },
    }
}
</script>

<style lang="scss" scoped>

</style>